import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-logout-question',
  templateUrl: './modal-logout-question.page.html',
  styleUrls: ['./modal-logout-question.page.scss'],
})
export class ModalLogoutQuestionPage {
  constructor(
    private modalController: ModalController
  ) { }

  async _is_Submit(flag) {
    let data = { res: flag };
    this.modalController.dismiss(data);
  }
}